const colors = {
  background: "#F9F9F9",
  primary: '#58504e',
  primaryLight: '#897e79',
  secondary: '#2ecc71',
  text: '#333333',
  imageBg: "#e1d5cb",
  buttonPrimary: '#58504e',
  highlight: "#3498db",
};

export default colors;