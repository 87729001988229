import React, { createContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children, initialLanguage }) => {
  const { lang } = useParams();
  const navigate = useNavigate();
  const [language, setLanguage] = useState(lang || initialLanguage || 'en');
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const module = await import(`../locales/${language}.json`);
        setTranslations(module.default);
      } catch (error) {
        console.error('Failed to load translations:', error);
        // Fallback to English if translation file is not found
        if (language !== 'en') {
          setLanguage('en');
          navigate('/en', { replace: true });
        }
      }
    };
    loadTranslations();
  }, [language, navigate]);

  useEffect(() => {
    if (lang && lang !== language) {
      setLanguage(lang);
    }
  }, [lang, language]);

  const changeLanguage = (newLang) => {
    if (newLang !== language) {
      setLanguage(newLang);
      navigate(`/${newLang}`, { replace: true });
    }
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage, translations }}>
      {children}
    </LanguageContext.Provider>
  );
};