import React, { useMemo } from 'react';

const AnimatedHeader = ({ fullText, scrollY, startOffset }) => {
  const visibleText = useMemo(() => {
    const effectiveScroll = Math.max(0, scrollY - startOffset);
    const textLength = Math.min(fullText.length, Math.floor(effectiveScroll / 10));
    return fullText.slice(0, textLength);
  }, [fullText, scrollY, startOffset]);

  return <>{visibleText}</>;
};

export default AnimatedHeader;