import React from "react";
import colors from "../config/colors";

const FeatureDisplay = ({ heading, subheading, image }) => {
  return (
    <div style={{
      display: 'flex',
      margin: '1.5rem',
      height: 'calc(50vh)',
      backgroundColor: "white",
      maxWidth: "60rem",
      marginBottom: '7rem',
      borderRadius: '.5rem',
    }}>
      <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
         padding: "2rem",
      }}>
        <div style={{
          padding: "2rem",
        }}>
          <h1
            style={{
              color: colors.primary,
              textAlign: "start",
              fontFamily: "'Slabo 13px', serif",
              fontSize: "2rem",
              lineHeight: "1.2",
              marginBottom: "1rem",
            }}
          >
            {heading}
          </h1>
          <h2
            style={{
              color: colors.primaryLight,
              fontSize: "1rem",
              textAlign: "start",
              lineHeight: "1.2",
              maxWidth: "90%",
            }}
          >
            {subheading}
          </h2>
        </div>
      </div>
      <div style={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.imageBg,
        borderRadius: ".5rem",
      }}>
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <img
            src={image}
            alt={heading}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FeatureDisplay;