import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from 'react-router-dom';
import { LanguageProvider } from './contexts/LanguageContext';
import Splash from './components/Splash';
import SplashNarrow from './components/SplashNarrow';
import NavBar from "./components/NavBar";
import Features from './components/Features';
import VideoPlayer from './components/VideoPlayer';
import Footer from './components/Footer';
import { PrivacyPolicy, Pricing, ContactUs } from './components/FooterPages';

const AppContent = () => {
  const [isButtonInNavbar, setIsButtonInNavbar] = useState(false);
  const [isNarrowScreen, setIsNarrowScreen] = useState(window.innerWidth < 1000);
  const { lang } = useParams();

  useEffect(() => {
    const handleResize = () => {
      setIsNarrowScreen(window.innerWidth < 1190);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleScrollToButton = () => {
    // Implement your scroll logic here
    console.log('Scrolled to button');
  };

  const SplashComponent = isNarrowScreen ? SplashNarrow : Splash;

  return (
    <LanguageProvider initialLanguage={lang}>
      <NavBar isButtonInNavbar={isButtonInNavbar} />
      <Routes>
        <Route path="/" element={
          <>
            <SplashComponent 
              handleScrollToButton={handleScrollToButton} 
              setIsButtonInNavbar={setIsButtonInNavbar}
            />
            <Features/>
            <VideoPlayer/>
          </>
        } />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="contact-us" element={<ContactUs />} />
      </Routes>
      <Footer />
    </LanguageProvider>
  );
};

const LanguageRedirect = () => {
  const userLanguage = navigator.language.split('-')[0];
  const supportedLanguages = ['en', 'es'];
  const defaultLanguage = 'en';
  
  const languageToUse = supportedLanguages.includes(userLanguage) ? userLanguage : defaultLanguage;
  
  return <Navigate to={`/${languageToUse}`} replace />;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/:lang/*" element={<AppContent />} />
        <Route path="/" element={<LanguageRedirect />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default App;