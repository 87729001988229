import React from "react";
import video from "../videos/demo.mp4";

const VideoPlayer = () => {
  const videoSrc = video;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#F9F9F9",
        padding: "20px",
        paddingBottom: "10rem",
      }}
    >
      <div style={{ width: "800px", maxWidth: "100%" }}>
        <video
          width="100%"
          height={360}
          controls
          style={{ display: "block" }}
        >
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default VideoPlayer;
