import React from 'react';

const AnimatedSubheader = ({ text, scrollY, startOffset }) => {
  const opacity = Math.min(Math.max((scrollY - startOffset) / 200, 0), 1);

  return (
    <p style={{
      opacity: opacity,
      transition: 'opacity 0.3s ease-in-out',
    }}>
      {text}
    </p>
  );
};

export default AnimatedSubheader;