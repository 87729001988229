import React, { useState, useEffect, useRef, useContext } from 'react';
import FeatureDisplay from "./FeatureDisplay";
import FeatureDisplayNarrow from "./FeatureDisplayNarrow";
import AnimatedHeader from './AnimatedHeader';
import AnimatedSubheader from './AnimatedSubheader';
import vocab from "../images/vocab.png";
import promptEng from "../images/prompt-eng.png";
import wordTranslation from "../images/word-translation.png";
import bookGen from "../images/book-gen.png"; // New image import

import colors from "../config/colors";
import { LanguageContext } from "../contexts/LanguageContext";

const Features = () => {
  const { translations } = useContext(LanguageContext);
  const [scrollY, setScrollY] = useState(0);
  const [isNarrow, setIsNarrow] = useState(false);
  const featuresRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (featuresRef.current) {
        const { top } = featuresRef.current.getBoundingClientRect();
        setScrollY(-top);
      }
    };
    const handleResize = () => {
      setIsNarrow(window.innerWidth < 800);
    };
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const FeatureComponent = isNarrow ? FeatureDisplayNarrow : FeatureDisplay;

  const featuresData = [
    {
      key: 'feature1',
      image: wordTranslation,
      startOffset: -1000,
    },
    {
      key: 'feature2',
      image: vocab,
      startOffset: -300,
    },
    {
      key: 'feature3',
      image: bookGen,
      startOffset: 500,
    },
    {
      key: 'feature4',
      image: promptEng,
      startOffset: 1000,
    }
  ];

  return (
    <div 
      ref={featuresRef}
      style={{
        flex: 1, 
        display: "flex", 
        alignItems: "center", 
        flexDirection: "column", 
        backgroundColor: colors.background
      }}
    >
      {featuresData.map((feature, index) => (
        <FeatureComponent
          key={index}
          leftImage={false}
          heading={
            <AnimatedHeader 
              fullText={translations?.features?.[feature.key]?.title || `Default Title ${index + 1}`} 
              scrollY={scrollY} 
              startOffset={feature.startOffset + 100} 
            />
          }
          subheading={
            <AnimatedSubheader 
              text={translations?.features?.[feature.key]?.description || `Default Description ${index + 1}`}
              scrollY={scrollY} 
              startOffset={feature.startOffset} 
            />
          }
          image={feature.image}
          scrollY={scrollY}
          startOffset={feature.startOffset}
        />
      ))}
    </div>
  );
};

export default Features;