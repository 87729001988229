import React, { useState, useEffect, useRef, useContext } from "react";
import colors from "../config/colors";
import EmailSubmissionButton from "./EmailSubmissionButton";
import AlyPoster from "./AlyPoster";
import { LanguageContext } from "../contexts/LanguageContext";

const SplashNarrow = ({ handleScrollToButton, setIsButtonInNavbar }) => {
  const { translations, language } = useContext(LanguageContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [headerText, setHeaderText] = useState("");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const hasScrolledRef = useRef(false);
  const animationPlayedRef = useRef(false);
  const fullHeaderText =
    translations?.header?.title || "Learn any language by reading";
  const [posterContainerOpacity, setPosterContainerOpacity] = useState(0);

  useEffect(() => {
    console.log("Current scroll position:", scrollPosition);
  }, [scrollPosition]);

  useEffect(() => {
    if (animationPlayedRef.current) {
      setHeaderText(fullHeaderText);
      setIsVisible(true);
      return;
    }

    const fadeInTimer = setTimeout(() => {
      setIsVisible(true);
    }, 500);

    const textAnimationTimer = setTimeout(() => {
      let charIndex = 0;
      const intervalId = setInterval(() => {
        if (charIndex <= fullHeaderText.length) {
          setHeaderText(fullHeaderText.slice(0, charIndex));
          charIndex++;
        } else {
          clearInterval(intervalId);
          animationPlayedRef.current = true;
        }
      }, 30);

      return () => clearInterval(intervalId);
    }, 500);

    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    const posterContainerFadeInTimer = setTimeout(() => {
      setPosterContainerOpacity(1);
    }, 0);

    return () => {
      clearTimeout(fadeInTimer);
      clearTimeout(textAnimationTimer);
      window.removeEventListener("resize", handleResize);
      clearTimeout(posterContainerFadeInTimer);
    };
  }, [fullHeaderText]);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);

      if (position > 450) {
        setIsButtonVisible(false);
        setIsButtonInNavbar(true);
        if (!hasScrolledRef.current) {
          handleScrollToButton();
          hasScrolledRef.current = true;
        }
      } else {
        setIsButtonVisible(true);
        setIsButtonInNavbar(false);
        hasScrolledRef.current = false;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScrollToButton, setIsButtonInNavbar]);

  const getHeaderFontSize = () => {
    if (windowWidth < 500) {
      return "2rem";
    }
    return "3rem";
  };

  const getSubheaderFontSize = () => {
    return "1rem";
  };

  const renderButton = () => (
    <EmailSubmissionButton 
      translations={translations} 
      color={colors.buttonPrimary} 
      textColor="white"
      language={language}
    />
  );

  return (
    <>
      <div
        style={{
          opacity: isVisible ? 1 : 0,
          transition: "opacity 1s ease-in-out",
          backgroundColor: colors.background,
        }}
      >
        <div
          style={{
            width: "100vw",
            height: "70vh",
            display: "flex",
            flexDirection: "column",
            backgroundColor: colors.background,
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                maxWidth: "80%",
                alignItems: "center",
              }}
            >
              <h1
                style={{
                  color: colors.primary,
                  textAlign: "center",
                  fontFamily: "'Slabo 13px', serif",
                  fontSize: getHeaderFontSize(),
                  lineHeight: "1.2",
                }}
              >
                {headerText}
              </h1>
              <h2
                style={{
                  color: colors.primaryLight,
                  fontSize: getSubheaderFontSize(),
                  textAlign: "center",
                  lineHeight: "1.2",
                  maxWidth: "90%",
                }}
              >
                {translations?.header?.subtitle ||
                  "Aly is an AI language learning app that helps you get the most out of your favourite books."}
              </h2>
              <div style={{ height: "3rem" }}>
                {isButtonVisible ? (
                  <div
                    style={{
                      transition: "opacity 0.3s ease-in-out",
                      marginTop: "3rem",
                    }}
                  >
                    {renderButton()}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          overflow: "hidden",
          paddingLeft: window.innerWidth < 500 ? "2rem" : "8rem",
          backgroundColor: colors.background,
          opacity: posterContainerOpacity,
          transition: "opacity 2s ease-in-out",
        }}
      >
        <AlyPoster />
      </div>
    </>
  );
};

export default SplashNarrow;
