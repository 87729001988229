import React, { useState, useEffect, useCallback } from 'react';
import world from "../animations/world.json"

const GRID_SIZE = 40;
const MOVE_INTERVAL = 2000; // ms between moves

// New function to determine cell size
const getCellSize = () => {
  return window.innerWidth < 500 ? 10 : 20;
};

const getRandomDirection = () => {
  const directions = [
    { dx: 1, dy: 0 },
    { dx: -1, dy: 0 },
    { dx: 0, dy: 1 },
    { dx: 0, dy: -1 },
  ];
  return directions[Math.floor(Math.random() * directions.length)];
};

const Character = ({ character, x, y, animationType, onMove, checkCollision }) => {
  const [position, setPosition] = useState({ x, y });
  const [direction, setDirection] = useState(
    animationType === 'glideAndBump' ? getRandomDirection() : { dx: 0, dy: 0 }
  );
  const [opacity, setOpacity] = useState(1);

  const cellSize = getCellSize();

  const move = useCallback(() => {
    if (animationType === 'glideAndBump') {
      let newDirection = direction;
      let newX = position.x + newDirection.dx;
      let newY = position.y + newDirection.dy;
      // Look-ahead collision check
      while (
        newX < 0 || newX >= GRID_SIZE || 
        newY < 0 || newY >= GRID_SIZE || 
        checkCollision(newX, newY, character)
      ) {
        newDirection = getRandomDirection();
        newX = position.x + newDirection.dx;
        newY = position.y + newDirection.dy;
      }
      // Update direction if it changed
      if (newDirection !== direction) {
        setDirection(newDirection);
      }
      // Move to the new position
      setPosition({ x: newX, y: newY });
      onMove(character, newX, newY);
    }
  }, [animationType, direction, position, character, onMove, checkCollision]);

  useEffect(() => {
    const interval = setInterval(move, MOVE_INTERVAL);
    return () => clearInterval(interval);
  }, [move]);

  useEffect(() => {
    if (animationType === 'flicker') {
      const flickerInterval = setInterval(() => {
        const newOpacity = Math.random();
        setOpacity(newOpacity);
      }, Math.random() * 1000 + 500); // Random interval between 500ms and 1500ms

      return () => clearInterval(flickerInterval);
    }
  }, [animationType]);

  return (
    <div
      style={{
        position: 'absolute',
        left: `${position.x * cellSize}px`,
        top: `${position.y * cellSize}px`,
        width: `${cellSize}px`,
        height: `${cellSize}px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '12px',
        fontWeight: 'bold',
        color: "white",
        backgroundColor: "#918A89",
        transition: animationType === 'flicker' 
          ? 'opacity 0.3s ease-in-out' 
          : `left ${MOVE_INTERVAL}ms linear, top ${MOVE_INTERVAL}ms linear`,
        borderRadius: "10px",
        opacity: animationType === 'flicker' ? opacity : 1,

      }}
    >
      {character}
    </div>
  );
};

const Grid = ({ characters: initialCharacters }) => {
  const [characters, setCharacters] = useState(initialCharacters);

  const onMove = useCallback((char, newX, newY) => {
    setCharacters(prev => prev.map(c => 
      c.character === char ? { ...c, startingPosition: { x: newX, y: newY } } : c
    ));
  }, []);

  const checkCollision = useCallback((x, y, currentChar) => {
    return characters.find(char => 
      char.character !== currentChar &&
      char.startingPosition.x === x &&
      char.startingPosition.y === y
    );
  }, [characters]);

  const cellSize = getCellSize();

  return (
    <div
      style={{
        position: 'relative',
        width: `${GRID_SIZE * cellSize}px`,
        height: `${GRID_SIZE * cellSize}px`,
        display: 'grid',
        gridTemplateColumns: `repeat(${GRID_SIZE}, ${cellSize}px)`,
        gridTemplateRows: `repeat(${GRID_SIZE}, ${cellSize}px)`,
      }}
    >
      {characters.map((char, index) => (
        <Character
          key={index}
          character={char.character}
          x={char.startingPosition.x}
          y={char.startingPosition.y}
          animationType={char.animationType}
          onMove={onMove}
          checkCollision={checkCollision}
        />
      ))}
    </div>
  );
};

const AlyPoster = () => {
  const characters = world;
  return (
    <div style={{ 
      width: "100%", 
      height: "100%", 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      padding: '20px'
    }}>
      <Grid characters={characters} />
    </div>
  );
};

export default AlyPoster;