import React, { useState } from 'react';

const GOOGLE_FORM_ACTION_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSdwHhD5vIiOknDtrXxuu-s84O4QEwb6Ew1-kPXoZlclTylq5Q/formResponse';
const GOOGLE_FORM_EMAIL_ID = 'entry.463453782';

const EmailSubmissionButton = ({ translations, textColor = '#ffffff', language }) => {
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState({ type: '', message: '' });

  const buttonStyle = {
    padding: '12px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: textColor,
    backgroundColor: "#3498db",
    border: 'none',
    borderRadius: '25px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const formData = new FormData();
      formData.append(GOOGLE_FORM_EMAIL_ID, `${email}~~~${language}`);

      const response = await fetch(GOOGLE_FORM_ACTION_URL, {
        method: 'POST',
        mode: 'no-cors',
        body: formData
      });

      if (response.type === 'opaque') {
        setEmail('');
        setStatus({ type: 'success', message: translations?.emailSubmission?.successMessage || 'Thanks for joining our waitlist!' });

        // Trigger Google Ads conversion event
        if (window.gtag) {
          window.gtag('event', 'conversion', {
            'send_to': 'AW-16702398105/1mzvCL6n4dIZEJm9qZw-',
            'value': 1.0,
            'currency': 'CAD'
          });
          console.log('Google Ads conversion event triggered');
        } else {
          console.warn('gtag function is not available');
        }

      } else {
        throw new Error('Unexpected response');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setStatus({ type: 'error', message: translations?.emailSubmission?.errorMessage || 'Failed to join waitlist. Please try again later.' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {!isInputVisible ? (
        <button
          style={buttonStyle}
          onClick={() => setIsInputVisible(true)}
        >
          {translations?.buttons?.joinWaitlist || "Join Waitlist"}
        </button>
      ) : (
        <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="email"
            placeholder={translations?.emailSubmission?.placeholder || "Enter your email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={isSubmitting}
            style={{
              padding: '12px 20px',
              fontWeight: 'bold',
              borderRadius: '5px',
              marginRight: '10px',
              fontSize: '16px',
              width: '200px',
              border: "none"
            }}
          />
          <button
            type="submit"
            disabled={isSubmitting}
            style={{
              ...buttonStyle,
              padding: '10px 15px',
              fontSize: '14px',
            }}
          >
            {isSubmitting ? (translations?.emailSubmission?.joiningText || 'Joining...') : (translations?.emailSubmission?.submitText || 'Submit')}
          </button>
        </form>
      )}
      {status.message && (
        <div style={{
          marginTop: '10px',
          padding: '10px',
          borderRadius: '5px',
          backgroundColor: status.type === 'success' ? '#d4edda' : '#f8d7da',
          color: status.type === 'success' ? '#155724' : '#721c24',
        }}>
          {status.message}
        </div>
      )}
    </div>
  );
};

export default EmailSubmissionButton;