import React, { useState, useEffect, useRef, useContext } from "react";
import colors from "../config/colors";
import EmailSubmissionButton from "./EmailSubmissionButton";
import AlyPoster from "./AlyPoster";
import { LanguageContext } from "../contexts/LanguageContext";

const Splash = ({ handleScrollToButton, setIsButtonInNavbar }) => {
  const { translations, language } = useContext(LanguageContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [headerText, setHeaderText] = useState("");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const hasScrolledRef = useRef(false);
  const animationPlayedRef = useRef(false);
  const fullHeaderText = translations?.header?.title || "Default Title";
  const [posterOpacity, setPosterOpacity] = useState(0);

  useEffect(() => {
    console.log("Current scroll position:", scrollPosition);
  }, [scrollPosition]);

  useEffect(() => {
    if (animationPlayedRef.current) {
      setHeaderText(fullHeaderText);
      setIsVisible(true);
      return;
    }

    let charIndex = 0;
    const intervalId = setInterval(() => {
      if (charIndex <= fullHeaderText.length) {
        setHeaderText(fullHeaderText.slice(0, charIndex));
        charIndex++;
      } else {
        clearInterval(intervalId);
        animationPlayedRef.current = true;
      }
    }, 30);

    return () => clearInterval(intervalId);
  }, [fullHeaderText]);
  // Fade-in effects
  useEffect(() => {
    const fadeInTimer = setTimeout(() => setIsVisible(true), 500);
    const posterFadeInTimer = setTimeout(() => setPosterOpacity(1), 1000);

    return () => {
      clearTimeout(fadeInTimer);
      clearTimeout(posterFadeInTimer);
    };
  }, []);

  // Window resize effect
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Scroll effect
  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);

      if (position > 450) {
        setIsButtonVisible(false);
        setIsButtonInNavbar(true);
        if (!hasScrolledRef.current) {
          handleScrollToButton();
          hasScrolledRef.current = true;
        }
      } else {
        setIsButtonVisible(true);
        setIsButtonInNavbar(false);
        hasScrolledRef.current = false;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScrollToButton, setIsButtonInNavbar]);

  const getHeaderFontSize = () => {
    return "3rem";
  };

  const getSubheaderFontSize = () => {
    return "1.2rem";
  };

  const getSloganLeftValue = () => {
    console.log(windowWidth);
    if (windowWidth <= 1300) return "-7rem";
    if (windowWidth <= 1450) return "-4rem";
    if (windowWidth <= 1600) return "-2rem";
    if (windowWidth <= 1700) return "2rem";
    if (windowWidth <= 1900) return "7rem";
    return "12rem";
  };

  const getPosterRightValue = () => {
    if (windowWidth <= 1300) return "-15%";
    if (windowWidth <= 1450) return "-10%";
    if (windowWidth <= 1600) return "-5%";
    if (windowWidth <= 1700) return "0";
    if (windowWidth <= 1900) return "5%";
    return "10%";
  };

  const renderButton = () => (
    <EmailSubmissionButton 
      translations={translations} 
      color={colors.buttonPrimary} 
      textColor="white"
      language={language}
    />
  );

  return (
    <div
      style={{
        opacity: isVisible ? 1 : 0,
        transition: "opacity 1s ease-in-out",
        overflow: "hidden",
        position: "relative",
        width: "100%",
        height: "100vh",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          backgroundColor: colors.background,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "13rem",
            left: getSloganLeftValue(),
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              maxWidth: "60%",
            }}
          >
            <h1
              style={{
                maxWidth: "50rem",
                color: colors.primary,
                textAlign: "start",
                fontFamily: "'Slabo 13px', serif",
                fontSize: getHeaderFontSize(),
                lineHeight: "1.2",
              }}
            >
              {headerText}
            </h1>
            <h2
              style={{
                maxWidth: "50rem",
                color: colors.primaryLight,
                fontSize: getSubheaderFontSize(),
                textAlign: "start",
                lineHeight: "1.2",
              }}
            >
              {translations?.header?.subtitle || "Default Subtitle"}
            </h2>
            {isButtonVisible && (
              <div
                style={{
                  marginTop: "2rem",
                  top: "30rem",
                  transition: "opacity 0.3s ease-in-out",
                  flex: 1,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {renderButton()}
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            flex: 1,
            position: "absolute",
            right: getPosterRightValue(),
            top: "4rem",
            opacity: posterOpacity,
            transition: "opacity 1s ease-in-out",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AlyPoster />
        </div>
      </div>
    </div>
  );
};

export default Splash;
