import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import colors from '../config/colors';

const styles = {
  container: {
    padding: '20px',
    paddingTop: "5rem",
    backgroundColor: colors.background,
    minHeight: '100vh',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: colors.primary,
    marginBottom: '10px',
  },
  text: {
    fontSize: '16px',
    color: colors.primary,
    marginBottom: '10px',
  },
  listItem: {
    fontSize: '16px',
    color: colors.primary,
    marginLeft: '20px',
    marginBottom: '5px',
  },
};

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const PrivacyPolicy = () => {
  const { translations } = useContext(LanguageContext);
  const content = translations?.footer?.privacyPolicyContent;

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>{content.title}</h1>
      <p style={styles.text}>{content.lastUpdated}</p>
      <p style={styles.text}>{content.introduction}</p>
      <h2 style={styles.title}>{content.informationCollection.title}</h2>
      <p style={styles.text}>{content.informationCollection.description}</p>
      <h3 style={styles.title}>{content.informationCollection.personalData.title}</h3>
      <p style={styles.text}>{content.informationCollection.personalData.description}</p>
      <ul>
        {content.informationCollection.personalData.items.map((item, index) => (
          <li key={index} style={styles.listItem}>{item}</li>
        ))}
      </ul>
      <h3 style={styles.title}>{content.informationCollection.usageData.title}</h3>
      <p style={styles.text}>{content.informationCollection.usageData.description}</p>
      <h2 style={styles.title}>{content.useOfData.title}</h2>
      <p style={styles.text}>{content.useOfData.description}</p>
      <ul>
        {content.useOfData.purposes.map((purpose, index) => (
          <li key={index} style={styles.listItem}>{purpose}</li>
        ))}
      </ul>
      <h2 style={styles.title}>{content.openAIApiKey.title}</h2>
      <p style={styles.text}>{content.openAIApiKey.description}</p>
      <h2 style={styles.title}>{content.transferOfData.title}</h2>
      <p style={styles.text}>{content.transferOfData.description}</p>
      <h2 style={styles.title}>{content.disclosureOfData.title}</h2>
      <p style={styles.text}>{content.disclosureOfData.description}</p>
      <ul>
        {content.disclosureOfData.reasons.map((reason, index) => (
          <li key={index} style={styles.listItem}>{reason}</li>
        ))}
      </ul>
      <h2 style={styles.title}>{content.securityOfData.title}</h2>
      <p style={styles.text}>{content.securityOfData.description}</p>
      <h2 style={styles.title}>{content.changesToPolicy.title}</h2>
      <p style={styles.text}>{content.changesToPolicy.description}</p>
      <h2 style={styles.title}>{content.contactUs.title}</h2>
      <p style={styles.text}>{content.contactUs.description}</p>
      <p style={styles.text}>{content.contactUs.email}</p>
      <p style={styles.text}>{content.disclaimer}</p>
    </div>
  );
};

export const Pricing = () => {
  const { translations } = useContext(LanguageContext);
  const content = translations?.footer?.pricingContent;

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>{content.title}</h1>
      <p style={styles.text}>{content.description}</p>
      {content.pricingModel.map((item, index) => (
        <p key={index} style={styles.listItem}>{`• ${item}`}</p>
      ))}
      <p style={styles.text}>{content.note}</p>
    </div>
  );
};

export const ContactUs = () => {
  const { translations } = useContext(LanguageContext);
  const content = translations?.footer?.contactUsContent;

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>{content.title}</h1>
      <p style={styles.text}>{content.description}</p>
      <p style={styles.text}>{content.email}</p>
      <p style={styles.text}>{content.response}</p>
    </div>
  );
};