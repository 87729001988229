import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../contexts/LanguageContext';
import colors from '../config/colors';

const Footer = () => {
  const { translations, language } = useContext(LanguageContext);

  return (
    <footer style={styles.footer}>
      <Link to={`/${language}/privacy-policy`} style={styles.linkText}>
        {translations?.footer?.privacyPolicy}
      </Link>
      <Link to={`/${language}/contact-us`} style={styles.linkText}>
        {translations?.footer?.contactUs}
      </Link>
      <Link to={`/${language}/pricing`} style={styles.linkText}>
        {translations?.footer?.pricing}
      </Link>
    </footer>
  );
};

const styles = {
  footer: {
    height: '60px',
    backgroundColor: `${colors.background}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingHorizontal: '20px',
  },
  linkText: {
    fontFamily: "'Slabo 13px', serif",
    fontWeight: '550',
    fontSize: '14px',
    color: colors.primary,
    textDecoration: 'none',
  },
};

export default Footer;